/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DocumentCategory } from '../models/DocumentCategory';
import type { DocumentType } from '../models/DocumentType';
import type { PbeTempDocumentFrontDto } from '../models/PbeTempDocumentFrontDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BlobService {

    /**
     * @param documentCategory 
     * @param blobName 
     * @returns any Success
     * @throws ApiError
     */
    public static deleteBlob(
documentCategory: DocumentCategory,
blobName: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/blob/{documentCategory}/{blobName}',
            path: {
                'documentCategory': documentCategory,
                'blobName': blobName,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @param documentCategory 
     * @param blobName 
     * @returns any Success
     * @throws ApiError
     */
    public static getBlob(
documentCategory: DocumentCategory,
blobName: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/blob/{documentCategory}/{blobName}',
            path: {
                'documentCategory': documentCategory,
                'blobName': blobName,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @param blobName 
     * @returns any Success
     * @throws ApiError
     */
    public static downloadBlob(
blobName: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/blob/{blobName}',
            path: {
                'blobName': blobName,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @param formData 
     * @returns PbeTempDocumentFrontDto Success
     * @throws ApiError
     */
    public static readImageMetadataBlob(
formData?: {
file: Blob;
},
): CancelablePromise<PbeTempDocumentFrontDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/blob/readimagemetadata',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @param documentCategory 
     * @param type 
     * @param formData 
     * @returns any Success
     * @throws ApiError
     */
    public static uploadBlob(
documentCategory?: DocumentCategory,
type?: DocumentType,
formData?: {
file: Blob;
},
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/blob/upload',
            query: {
                'documentCategory': documentCategory,
                'type': type,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                500: `Server Error`,
            },
        });
    }

}
