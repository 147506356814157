/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AdvisoPbeSessionDto } from '../models/AdvisoPbeSessionDto';
import type { AdvisoPbeSessionFilterDto } from '../models/AdvisoPbeSessionFilterDto';
import type { ContactDomainDto } from '../models/ContactDomainDto';
import type { ContactEmployeDepartmentDto } from '../models/ContactEmployeDepartmentDto';
import type { ContactThematicDto } from '../models/ContactThematicDto';
import type { EventsDataDto } from '../models/EventsDataDto';
import type { FaqDataDto } from '../models/FaqDataDto';
import type { GrantDataDto } from '../models/GrantDataDto';
import type { LivesDataDto } from '../models/LivesDataDto';
import type { NewsDataDto } from '../models/NewsDataDto';
import type { PageDataDto } from '../models/PageDataDto';
import type { PrizeDataDto } from '../models/PrizeDataDto';
import type { SingleNewsDataDto } from '../models/SingleNewsDataDto';
import type { SloganDataDto } from '../models/SloganDataDto';
import type { TagsDataDto } from '../models/TagsDataDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AddvisoService {

    /**
     * @param domainId 
     * @param thematiciId 
     * @returns ContactEmployeDepartmentDto Success
     * @throws ApiError
     */
    public static getDepartmentsAndEmployeesAddviso(
domainId?: number,
thematiciId?: number,
): CancelablePromise<ContactEmployeDepartmentDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/addviso/getdepartmentsandemployees',
            query: {
                'domainId': domainId,
                'thematiciId': thematiciId,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @returns ContactDomainDto Success
     * @throws ApiError
     */
    public static getDomainsAddviso(): CancelablePromise<Array<ContactDomainDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/addviso/domains',
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @param fields 
     * @param limit 
     * @param order 
     * @param orderby 
     * @param perPage 
     * @param sitePublication 
     * @returns EventsDataDto Success
     * @throws ApiError
     */
    public static getEventsAddviso(
fields?: string,
limit?: number,
order?: string,
orderby?: string,
perPage?: number,
sitePublication?: number,
): CancelablePromise<Array<EventsDataDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/addviso/events',
            query: {
                '_fields': fields,
                'limit': limit,
                'order': order,
                'orderby': orderby,
                'per_page': perPage,
                'site_publication': sitePublication,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @param eventsId 
     * @returns EventsDataDto Success
     * @throws ApiError
     */
    public static getEventsByIdAddviso(
eventsId: number,
): CancelablePromise<EventsDataDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/addviso/events/{eventsId}',
            path: {
                'eventsId': eventsId,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @returns FaqDataDto Success
     * @throws ApiError
     */
    public static getFaqAddviso(): CancelablePromise<Array<FaqDataDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/addviso/faq',
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @param faqId 
     * @returns FaqDataDto Success
     * @throws ApiError
     */
    public static getFaqByIdAddviso(
faqId: number,
): CancelablePromise<FaqDataDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/addviso/faq/{faqId}',
            path: {
                'faqId': faqId,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @param grantId 
     * @returns GrantDataDto Success
     * @throws ApiError
     */
    public static getGrantByIdAddviso(
grantId: number,
): CancelablePromise<GrantDataDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/addviso/grant/{grantId}',
            path: {
                'grantId': grantId,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @param status 
     * @param sitePublications 
     * @returns LivesDataDto Success
     * @throws ApiError
     */
    public static getLivesAddviso(
status?: string,
sitePublications?: string,
): CancelablePromise<Array<LivesDataDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/addviso/lives',
            query: {
                'status': status,
                'sitePublications': sitePublications,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @param perPage 
     * @param page 
     * @param sticky 
     * @param tags 
     * @param entitledPerson 
     * @param categories 
     * @param domaineCreation 
     * @param typePublication 
     * @param filter 
     * @param sitePublication 
     * @param order 
     * @param orderby 
     * @param fields 
     * @returns NewsDataDto Success
     * @throws ApiError
     */
    public static getNewsAddviso(
perPage?: number,
page?: number,
sticky?: string,
tags?: number,
entitledPerson?: number,
categories?: number,
domaineCreation?: number,
typePublication?: number,
filter?: boolean,
sitePublication?: string,
order?: string,
orderby?: string,
fields?: string,
): CancelablePromise<Array<NewsDataDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/addviso/news',
            query: {
                'per_page': perPage,
                'page': page,
                'sticky': sticky,
                'tags': tags,
                'entitled_person': entitledPerson,
                'categories': categories,
                'domaine_creation': domaineCreation,
                'type_publication': typePublication,
                'filter': filter,
                'site_publication': sitePublication,
                'order': order,
                'orderby': orderby,
                '_fields': fields,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @param newsId 
     * @param fields 
     * @param perPage 
     * @returns SingleNewsDataDto Success
     * @throws ApiError
     */
    public static getNewsByIdAddviso(
newsId: number,
fields?: string,
perPage?: number,
): CancelablePromise<SingleNewsDataDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/addviso/news/{newsId}',
            path: {
                'newsId': newsId,
            },
            query: {
                '_fields': fields,
                'per_page': perPage,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @param pageId 
     * @returns PageDataDto Success
     * @throws ApiError
     */
    public static getPageByIdAddviso(
pageId: number,
): CancelablePromise<PageDataDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/addviso/page/{pageId}',
            path: {
                'pageId': pageId,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns AdvisoPbeSessionDto Success
     * @throws ApiError
     */
    public static getPbeSessionsAdvisoAddviso(
requestBody?: AdvisoPbeSessionFilterDto,
): CancelablePromise<Array<AdvisoPbeSessionDto>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/addviso/getpbesessionsaddviso',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @param prizeId 
     * @returns PrizeDataDto Success
     * @throws ApiError
     */
    public static getPrizeByIdAddviso(
prizeId: number,
): CancelablePromise<PrizeDataDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/addviso/prize/{prizeId}',
            path: {
                'prizeId': prizeId,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @returns SloganDataDto Success
     * @throws ApiError
     */
    public static getSloganAddviso(): CancelablePromise<SloganDataDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/addviso/slogan',
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @param tagName 
     * @returns TagsDataDto Success
     * @throws ApiError
     */
    public static getTagsAddviso(
tagName: string,
): CancelablePromise<Array<TagsDataDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/addviso/tags/{tagName}',
            path: {
                'tagName': tagName,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @returns ContactThematicDto Success
     * @throws ApiError
     */
    public static getThematicsAddviso(): CancelablePromise<Array<ContactThematicDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/addviso/thematics',
            errors: {
                500: `Server Error`,
            },
        });
    }

}
